import React from "react";
import moment from "moment";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ClientOnly } from "react-client-only";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Arrow from "./arrow";
import Label from "./label";
import {
  addEventContextAndSort,
  DATE_FORMAT,
  getUpcomingAndCurrentEvents,
  toKabobCase,
} from "../utils";

const UpcomingEventsSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      allEventsJson {
        events: nodes {
          title
          labels
          start_date
          end_date
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                height: 250
                placeholder: BLURRED
                quality: 60
              )
            }
          }
        }
      }
    }
  `);

  const events = addEventContextAndSort(data.allEventsJson.events);
  const upcomingEvents = getUpcomingAndCurrentEvents(events).slice(0, 6);

  const isEmpty = upcomingEvents <= 0;

  const baseSettings = {
    dots: true,
    variableWidth: true,
    infinite: false,
    centerMode: true,
    className:
      "md:!container !mx-auto with-container with-gap with-stretch with-overflow",
    nextArrow: <Arrow next />,
    prevArrow: <Arrow prev />,
  };

  const settings = {
    ...baseSettings,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          ...baseSettings,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div
      className="loading-spinner mb-4 overflow-hidden"
      style={{ height: !isEmpty ? 425 : 200 }}
    >
      {!isEmpty ? (
        <ClientOnly>
          <Slider {...settings}>
            {upcomingEvents.map(
              ({ title, labels, image, start_date, end_date }, index) => (
                <div
                  key={index}
                  className="bg-vip-pink border-2 overflow-hidden rounded-xl h-full"
                  style={{ width: 300 }}
                >
                  <div className="overflow-hidden" style={{ height: "250px" }}>
                    {image && (
                      <GatsbyImage
                        image={getImage(image)}
                        alt={title}
                        style={{
                          height: "250px",
                        }}
                        imgStyle={{
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div className="p-6">
                    <Link
                      to={`/events/#${toKabobCase(title) + "-" + start_date}`}
                      className="h2 !mb-3 block"
                    >
                      {title}
                    </Link>
                    <div className="mb-3">
                      {moment(start_date, DATE_FORMAT).format("M/D/YYYY")}
                      {end_date
                        ? " - " +
                          moment(end_date, DATE_FORMAT).format("M/D/YYYY")
                        : ""}
                    </div>
                    <div>
                      {labels?.map((label, index) => (
                        <Label
                          key={index}
                          className={index !== labels.length ? "mr-2" : ""}
                          label={label}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
        </ClientOnly>
      ) : (
        <div
          className="flex flex-col items-center justify-center"
          style={{ height: "100%" }}
        >
          <span className="h3">There are currently no upcoming events</span>
          <span>Check back soon to see what exciting things are to come</span>
        </div>
      )}
    </div>
  );
};

export default UpcomingEventsSlider;
