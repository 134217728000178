import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Arrow = ({ next, prev, className, style, onClick }) => {
  return (
    <button
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={prev ? faChevronLeft : next ? faChevronRight : null}
        size="2x"
      />
    </button>
  );
};

export default Arrow;
