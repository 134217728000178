import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerLarge: file(relativePath: { eq: "banner2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 70)
        }
      }
      bannerSmall: file(relativePath: { eq: "banner2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 70)
        }
      }
    }
  `);

  const bannerImages = withArtDirection(getImage(data.bannerLarge), [
    {
      media: "(max-width: 1024px)",
      image: getImage(data.bannerSmall),
    },
  ]);

  return (
    <section className="banner relative" style={{ height: "700px" }}>
      <div style={{ backgroundColor: "rgba(5, 5, 5, 0.4)", height: "100%" }}>
        <GatsbyImage
          image={bannerImages}
          alt="banner image"
          className="object-cover object-center"
          style={{ height: "100%", zIndex: "-1" }}
        />
      </div>
      <div className="container p-0">
        <div
          className="absolute"
          style={{ top: "50%", transform: "translate(0, -50%)" }}
        >
          <div className="container flex flex-col items-start">
            <span className="text-5xl text-white mb-4">
              Welcome to <br /> Very Important Pets!
            </span>
            <span className="text-white text-2xl mb-4">
              A home away from home <br />
              for your Very Important Pet
            </span>
            <Link to="/about/" className="btn btn-light">
              About Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
