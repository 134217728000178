import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";

const AdoptablePetOfTheMonth = ({
  petName,
  petAge,
  petBio,
  petBreed,
  petImage,
  bioLink,
}) => {
  const data = useStaticQuery(graphql`
    query {
      zigZagBackground: file(relativePath: { eq: "zig_zag_pink.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
    }
  `);

  return (
    <div className="border-2 border-vip-purple rounded-xl mb-4 overflow-hidden">
      <div className="bg-vip-turquoise text-center p-4">
        <h2
          className="text-3xl sm:text-4xl md:text-5xl font-normal !mb-0"
          style={{ color: "#0f2b2b" }}
        >
          Adoptable Pet of the Month
        </h2>
      </div>

      <div
        className="flex flex-col lg:flex-row bg-blue-200"
        style={{
          backgroundImage: `url(${getSrc(data.zigZagBackground)})`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "100%",
        }}
      >
        <div className="lg:w-1/2 p-8  lg:self-center">
          {petImage && (
            <GatsbyImage
              image={getImage(petImage)}
              alt={`Image of ${petName}`}
              className="block mx-auto mb-4 rounded-xl"
              style={{ maxWidth: "350px" }}
            />
          )}
          <span className="block text-4xl text-center">{petName}</span>
        </div>

        <div className="bg-white text-xl md:text-2xl lg:w-1/2 p-8">
          <h3 className="text-2xl sm:text-3xl font-normal text-center">
            I'm looking for a NEW home!
          </h3>
          {petAge && (
            <span className="block mb-3">
              <span className="font-bold">My age:</span> {petAge}
            </span>
          )}
          {petBreed && (
            <span className="block mb-3">
              <span className="font-bold">I am a:</span> {petBreed}
            </span>
          )}
          {petBio && (
            <span className="block mb-3">
              <span className="font-bold">About Me:</span> {petBio}
            </span>
          )}
          {bioLink && (
            <a href={bioLink} className="btn btn-light text-xl">
              View My Profile
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export const PetOfTheMonthImage = graphql`
  fragment AdoptablePetOfTheMonthImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
`;

export default AdoptablePetOfTheMonth;
