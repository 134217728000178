import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Banner from '../components/banner';
import PetOfTheMonth from '../components/petOfTheMonth';
import AdoptablePetOfTheMonth from '../components/adoptablePetOfTheMonth';
import UpcomingEventsSlider from '../components/upcomingEventsSlider';
import ReviewsSlider from '../components/reviewsSlider';
import RatingStars from '../components/ratingStars';

const Index = ({ data }) => {
  var pageTitle = 'Home';

  const petOfTheMonth = data.allFeaturedPetsJson.nodes[0];

  return (
    <Layout
      slug="/"
      description="Welcome to Very Important Pets! A home away from home for your Very Important Pet!"
      noContainer
    >
      <h1 className="sr-only">{pageTitle}</h1>

      <Banner />

      <div className="container mt-20">
        <section className="mb-20 text-center">
          <h2 className="h1">Our 5 star rating</h2>
          <RatingStars
            className="!inline-flex text-yellow-300 mb-3"
            rating={5}
          />
          <span className="h2 block">
            reflects the excellent service we provide
          </span>
          <Link to="#reviews" className="btn btn-light">
            See What Customers Are Saying
          </Link>
        </section>
      </div>

      <div className="container mt-20">
        <section className="flex flex-col lg:flex-row lg:items-center mb-20">
          <div className="lg:w-1/2 mb-6 lg:px-10">
            <h2>Complete K9 Raw Food</h2>
            <p>
              We are once again a distributor for The Complete K9! Along with
              pick up on location we are also offering deliveries to Stettler
              and Castor. To learn more about The Complete K9 or for more
              information about feeding raw food follow the link below!
            </p>
            <Link to="/raw-food/" className="btn btn-light">
              More Information
            </Link>
          </div>
          <div className="lg:w-1/2">
            <GatsbyImage
              image={getImage(data.image5)}
              alt="Complete K9"
              className="rounded-xl"
            />
          </div>
        </section>
      </div>

      <div className="mt-20">
        <section className="mb-20">
          <h2 className="h1 text-center">Upcoming Events</h2>
          <UpcomingEventsSlider />
          <div className="flex justify-center">
            <Link to="/events/" className="btn btn-light">
              View All Events
            </Link>
          </div>
        </section>
      </div>

      <section
        className="bg-no-repeat bg-cover bg-center bg-fixed"
        style={{
          height: 450,
          backgroundImage: `url(${getSrc(data.parallaxImage)})`,
        }}
      ></section>

      <div className="container mt-20">
        <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
          <div className="lg:w-1/2 mb-6 lg:px-10">
            <h2>Our Team</h2>
            <p>
              At Very Important Pets we take pride in ensuring that we have
              knowledgeable, capable staff members to guarantee that your pet
              receives nothing but the best while in our care.
            </p>
            <Link to="/about/" className="btn btn-light">
              About Us
            </Link>
          </div>
          <div className="lg:w-1/2">
            <GatsbyImage
              image={getImage(data.image1)}
              alt="Our Team"
              className="rounded-xl"
            />
          </div>
        </section>

        <section className="mb-20">
          <h2 className="h1 text-center">New and Upcoming Services</h2>
          <div className="flex flex-wrap lg:gap-6 xl:gap-0 justify-center">
            <Link to="/dog-grooming/#at-home-nail-trims">
              <div className="image-overlay square-350 rounded-t-xl lg:rounded-xl xl:rounded-r-none">
                <GatsbyImage
                  image={getImage(data.image4)}
                  alt="At Home Nail Trims"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
                <div className="image-overlay-text">
                  <span className="h1 !mb-0">At Home Nail Trims</span>
                </div>
              </div>
            </Link>
            <Link to="/dog-grooming/#private-kennel-free-grooming">
              <div className="image-overlay square-350 xl:ml-0 lg:rounded-xl xl:rounded-none">
                <GatsbyImage
                  image={getImage(data.image2)}
                  alt="Private Kennel Free Grooming"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
                <div className="image-overlay-text">
                  <span className="h1 !mb-0">Private Kennel Free Grooming</span>
                </div>
              </div>
            </Link>
            <Link to="/future-plans/#countryside-walks">
              <div className="image-overlay square-350 rounded-b-xl lg:rounded-xl xl:rounded-l-none">
                <GatsbyImage
                  image={getImage(data.image3)}
                  alt="Country Side Walks"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
                <div className="image-overlay-text">
                  <span className="h1 !mb-0">Sniffy Walks</span>
                </div>
              </div>
            </Link>
          </div>
        </section>

        <div className="container mt-20">
          <section className="mb-20">
            <AdoptablePetOfTheMonth
              petName="Frank"
              petBreed="Lab/Bordre Collie"
              petBio="Meet Frank, Our adoptable pet of the month!
This handsome boy is Frank!  He is a big boy, but gentle and so much fun.  Frank is a 7 month old,  Lab/border collie cross and he is house, kennel and leash trained.  Frank adores kids, likes other dogs and is learning how to be friends with cats. He is a very smart puppy who learns quickly and loves cuddles and belly rubs. Frank is playful but not as high energy as a Border Collie would be. This gentle boy is neutered, microchipped and up to date with his vaccinations. He is all ready for his forever home!"
              petImage={data.adoptablePetOfTheMonthImage}
              bioLink="http://www.animalhavenrescue.ca/adoptable-dogs.html"
            />
          </section>
        </div>

        {/* <section className="mb-20">
          <PetOfTheMonth
            month={petOfTheMonth.month}
            petName={petOfTheMonth.name}
            petBirth={petOfTheMonth.birth}
            petFact={petOfTheMonth.fact}
            petBreed={petOfTheMonth.breed}
            petToy={petOfTheMonth.toy}
            petTreat={petOfTheMonth.treat}
            petActivity={petOfTheMonth.activity}
            petImage={petOfTheMonth.image}
          />
          <div className="flex justify-center mb-6">
            <Link className="btn btn-light" to="/pets-of-the-month/">
              View Previous Months
            </Link>
          </div>
        </section> */}
      </div>

      <div className="md:container mt-20">
        <section id="reviews" className="mb-20">
          <ReviewsSlider />
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "completek9.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "dancer.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          transformOptions: { cropFocus: EAST }
        )
      }
    }
    image4: file(relativePath: { eq: "nail_trim.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "potm/elsie.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          transformOptions: { cropFocus: EAST }
        )
      }
    }
    allFeaturedPetsJson(
      sort: { fields: [year, month], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        month
        name
        birth
        breed
        activity
        fact
        toy
        treat
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    adoptablePetOfTheMonthImage: file(relativePath: { eq: "apotm/frank.png" }) {
      ...AdoptablePetOfTheMonthImage
    }
    parallaxImage: file(relativePath: { eq: "oliver_front.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
`;

export default Index;
