import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faStar as faWholeStar,
  faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";

const RatingStars = ({ className, rating, size }) => {
  return (
    <div className={classNames("flex", className)}>
      {Array.from({ length: 5 }).map((v, i) => (
        <span key={i}>
          <FontAwesomeIcon
            icon={
              typeof rating === "number" &&
              !Number.isInteger(rating) &&
              Math.round(rating) === i + 1
                ? faStarHalfAlt
                : i + 1 <= rating
                ? faWholeStar
                : faStar
            }
            size={size || "2x"}
          />
        </span>
      ))}
    </div>
  );
};

export default RatingStars;
