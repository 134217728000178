import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ClientOnly } from 'react-client-only';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import Arrow from './arrow';
import RatingStars from './ratingStars';

const ReviewsSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      allReviewsJson {
        reviews: nodes {
          name
          rating
          review_date
          comment
        }
      }
    }
  `);

  const baseSettings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 7000,
    adaptiveHeight: true,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow prev />,
  };

  const settings = {
    ...baseSettings,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          ...baseSettings,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="loading-spinner" style={{ minHeight: 284 }}>
      <ClientOnly>
        <Slider {...settings}>
          {data.allReviewsJson.reviews.map(
            ({ name, rating, review_date, comment }, index) => (
              <div key={index}>
                <div className="flex flex-col justify-center items-center text-center max-w-lg mx-auto my-10">
                  <h2 className="mb-1">{name}</h2>
                  <span className="mb-1">{review_date}</span>
                  <RatingStars
                    className="text-yellow-300 mb-1"
                    rating={rating}
                  />
                  {comment && (
                    <p className="px-6 text-lg font-semibold">"{comment}"</p>
                  )}
                </div>
              </div>
            )
          )}
        </Slider>
      </ClientOnly>
    </div>
  );
};

export default ReviewsSlider;
